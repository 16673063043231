define("discourse/plugins/categories-separator/discourse/helpers/categories-separator", ["discourse-common/lib/helpers"], function (_helpers) {
  "use strict";

  (0, _helpers.registerUnbound)('category-with-separator', categoryId => {
    var listCategories = Discourse.SiteSettings.categories_separator_list.split(',');
    return listCategories.indexOf(categoryId + '') > -1;
  });
  (0, _helpers.registerUnbound)('category-separator-title', categoryId => {
    var listCategories = Discourse.SiteSettings.categories_separator_list.split(',');
    var index = listCategories.indexOf(categoryId + '');
    var listTitle = Discourse.SiteSettings.categories_separator_list_title.split(',');
    console.log(listTitle);
    return listTitle[index];
  });
});