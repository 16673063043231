define("discourse/plugins/categories-separator/initializers/categories-separator", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function oldPluginCode() {
    // migrate your old plugin code here. It will only be run if no PluginAPI  is present
    //  console.log("in old code initializer block");
  }
  function initializePlugin(api) {
    // do stuff with plugin API!
    //console.log("in plugin API initializer block");
  }
  var _default = _exports.default = {
    name: 'discourse-plugin-categories-separator',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.1', initializePlugin, {
        noApi: oldPluginCode
      });
    }
  };
});